import React from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';

import PageLoader from '../../components/PageLoader';

import WiseOwlLogo from '../../assets/images/logos/owl-logo.png';
import SkipLogo from '../../assets/images/logos/skipin-logo-blue.svg';
import SkipinPricingTable from '../../assets/images/skipin-pricing-table.png';
import CodeEntryScreenshot from '../../assets/images/code-entry-screenshot.png';

import {
  BASE_API_URL,
  AGENT_REGISTRATION_API,
  MEDIA_DIRECTORY,
  CDN_API_URL,
  CDN_MEDIA_API,
} from '../../constants/constants';

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        email: '',
        panCard: '',
        lastName: '',
        ifscCode: '',
        firstName: '',
        mobileNumber: '',
        panCardNumber: '',
        accountNumber: '',
        countryCode: '91',
        agreeTerms: false,
        consentForTDSProvided: false,
      },
      pageLoader: false,
      validationErrors: [],
      isSubmitEnabled: true,
      isFormSubmitted: false,
    };

    this.agentFormRef = React.createRef();

    this.axiosInstance = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.initializeAxiosInstance();
  }

  initializeAxiosInstance = () => {
    this.axiosInstance = axios.create({
      timeout: 60000,
      headers: {
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      withCredentials: false,
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  handleFieldUpdate = (fieldName, fieldValue) => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [`${fieldName}`]: fieldValue,
      },
    }));
  };

  validateFormData = () => {
    let validationErrors = [];
    const { formData } = this.state;

    this.setState({ validationErrors: [] });

    if (formData.firstName === '') {
      validationErrors.push(
        <div className='validation-errors-item'>First Name is required.</div>
      );
    }

    if (formData.lastName === '') {
      validationErrors.push(
        <div className='validation-errors-item'>Last Name is required.</div>
      );
    }

    if (formData.email === '') {
      validationErrors.push(
        <div className='validation-errors-item'>Email is required.</div>
      );
    } else if (!/.+@.+\..+/.test(formData.email)) {
      validationErrors.push(
        <div className='validation-errors-item'>Email is invalid.</div>
      );
    }

    if (formData.mobileNumber === '') {
      validationErrors.push(
        <div className='validation-errors-item'>Mobile Number is required.</div>
      );
    } else if (!/^[0-9]*$/.test(formData.mobileNumber)) {
      validationErrors.push(
        <div className='validation-errors-item'>Mobile Number is invalid.</div>
      );
    } else if (formData.mobileNumber.length !== 10) {
      validationErrors.push(
        <div className='validation-errors-item'>
          Mobile Number must be 10 characters long.
        </div>
      );
    }

    if (formData.consentForTDSProvided) {
      if (formData.panCardNumber === '') {
        validationErrors.push(
          <div className='validation-errors-item'>
            PAN Card Number is required.
          </div>
        );
      } else if (!/[A-Za-z]{5}\d{4}[A-Za-z]{1}/.test(formData.panCardNumber)) {
        validationErrors.push(
          <div className='validation-errors-item'>
            PAN Card Number is invalid.
          </div>
        );
      }
    }

    if (formData.accountNumber === '') {
      validationErrors.push(
        <div className='validation-errors-item'>
          Account Number is required.
        </div>
      );
    }

    if (formData.ifscCode === '') {
      validationErrors.push(
        <div className='validation-errors-item'>IFSC Code is required.</div>
      );
    } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(formData.ifscCode)) {
      validationErrors.push(
        <div className='validation-errors-item'>IFSC Code is invalid.</div>
      );
    }

    if (formData.consentForTDSProvided) {
      if (formData.panCard === '') {
        validationErrors.push(
          <div className='validation-errors-item'>
            Uploading PAN Card is mandatory.
          </div>
        );
      }
    }

    if (!formData.agreeTerms) {
      validationErrors.push(
        <div className='validation-errors-item'>
          Please accept the Wise Owl Learning Terms & Conditions.
        </div>
      );
    }

    this.setState((state) => ({
      ...state,
      validationErrors: validationErrors,
    }));

    if (validationErrors.length !== 0) {
      this.handleScrollToForm();
      return false;
    } else {
      return true;
    }
  };

  clearFormData = () => {
    this.setState((state) => ({
      ...state,
      formData: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        countryCode: '91',
        email: '',
        panCard: '',
        accountNumber: '',
        ifscCode: '',
        panCardNumber: '',
        consentForTDSProvided: false,
      },
      validationErrors: [],
      isSubmitEnabled: true,
      isFormSubmitted: false,
    }));
  };

  handleFormSubmit = () => {
    const { formData } = this.state;

    let isFormValid = this.validateFormData();

    if (isFormValid) {
      this.setState({ isSubmitEnabled: false, pageLoader: true });

      this.axiosInstance
        .post(`${BASE_API_URL}${AGENT_REGISTRATION_API}`, formData)
        .then((responseObject) => {
          this.setState({
            isSubmitEnabled: true,
            isFormSubmitted: false,
            pageLoader: false,
          });

          this.clearFormData();

          this.setState({ isFormSubmitted: true });
        })
        .catch((errorData) => {
          this.setState({
            isSubmitEnabled: true,
            isFormSubmitted: false,
            pageLoader: false,
          });

          alert(
            `Error! The request could not be processed due to the following error - ${errorData.response.data.message}`
          );
        });
    }
  };

  handleFileUpload = (event) => {
    if (!event || !event.target || !event.target.files.length) {
      return;
    }

    const file = event.target.files[0];

    let validationErrors = [];

    if (!file) {
      return;
    } else if (file.size / 1024 / 1024 > 5) {
      validationErrors.push(
        <div className='validation-errors-item'>
          Uploaded PAN Card is too large. Maximum file size permitted is 5 MB.
        </div>
      );
    } else if (
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'application/pdf'
    ) {
      validationErrors.push(
        <div className='validation-errors-item'>
          Uploaded PAN Card should be a JPG/PNG/PDF file.
        </div>
      );
    }

    this.setState((state) => ({
      ...state,
      validationErrors,
    }));

    if (validationErrors.length) {
      this.handleScrollToForm();
      return;
    }

    this.setState({ pageLoader: true });

    const dataPayload = new FormData();

    dataPayload.append('file', file);
    dataPayload.set('directoryName', MEDIA_DIRECTORY);

    this.axiosInstance
      .post(`${CDN_API_URL}${CDN_MEDIA_API}`, dataPayload)
      .then((responseData) => {
        this.setState({
          pageLoader: false,
        });

        if (responseData.data) {
          this.handleFieldUpdate('panCard', responseData.data.url || '');
        }
      })
      .catch((errorData) => {
        this.setState({
          pageLoader: false,
        });

        alert(
          'Error - Something went wrong with the file upload. Please try again later.'
        );
      });
  };

  handleTDSConsent = () => {
    const resetPanData = this.state.formData.consentForTDSProvided
      ? { panCardNumber: '', panCard: '' }
      : {};
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        consentForTDSProvided: !state.formData.consentForTDSProvided,
        ...resetPanData,
      },
    }));
  };

  handleCheckboxUpdate = () => {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        agreeTerms: !state.formData.agreeTerms,
      },
    }));
  };

  handleScrollToForm = () => {
    window.scrollTo(0, this.agentFormRef.current.offsetTop);
  };

  render() {
    const {
      formData,
      pageLoader,
      isSubmitEnabled,
      validationErrors,
      isFormSubmitted,
    } = this.state;

    return (
      <>
        <header className='header-section'>
          <Link to='/'>
            <img alt='Skipin' src={SkipLogo} className='header-section-logo' />
          </Link>
        </header>

        <main className='container-wrapper'>
          {!isFormSubmitted ? (
            <div className='container-wrapper-form'>
              <div className='header-subsection'>
                <h5 className='header-subsection-title'>
                  Skipin Ambassador Programme
                </h5>
              </div>

              <div className='content-section'>
                <div className='content-section-wrapper'>
                  <h4 className='content-section-heading'>Start Skipin!!</h4>

                  <p className='content-section-paragraph'>
                    Welcome to Skipin Ambassador Programme. Here you can join an
                    enthusiastic community of Skipin lovers and help students
                    onboard Skipin. You have an opportunity to earn attractive
                    referral commissions. On top of that, loads of rewards and
                    recognitions are waiting for Super Ambassadors.
                  </p>

                  <p className='content-section-paragraph'>
                    Skipin is a world class learning programme not only for your
                    own child but also for all the children that you know in
                    your family and community. Skipin is a wonderful gift for
                    birthdays, festivals and all special occasions. Skipin's
                    micro subscription fits all pockets and budgets.
                  </p>

                  <p className='content-section-paragraph'>
                    Know more about Skipin at{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://www.skipin.io'
                    >
                      https://www.skipin.io
                    </a>
                  </p>
                </div>

                <div className='content-section-wrapper'>
                  <h4 className='content-section-heading'>
                    Referral - Price - Commission
                  </h4>

                  <p className='content-section-paragraph'>
                    Skipin ambassadors get handsome and instant commissions on a
                    successful sale of Skipin. Rate of commission is very
                    attractive: 33% of sale value (before tax).
                  </p>

                  <p className='content-section-paragraph'>
                    Please see Skipin price list and Referral commission in the
                    table below.
                  </p>

                  <p className='content-section-paragraph'>
                    Note: Skipin price is before tax. Referral commission is
                    before TDS.
                  </p>

                  <img
                    className='img-fluid'
                    src={SkipinPricingTable}
                    alt='Skipin Pricing & Commission Table'
                  />
                </div>

                <div className='content-section-wrapper'>
                  <h4 className='content-section-heading'>Register and Sell</h4>
                  <p className='content-section-paragraph'>
                    You can register as a Skipin Ambassador by filling up the
                    form given at the bottom of this page. After registration,
                    you will receive a unique code by email. You need to enter
                    this code in the app every time you make a sale to tag the
                    sale with your name and bank account.
                  </p>

                  <p className='content-section-paragraph'>
                    Ask your referrals to enter your code on this screen of the
                    Skipin app.
                  </p>

                  {/* TODO: Add This Screenshot with Updated Pricing from the App */}
                  {/* <img
                    alt='Screenshot'
                    src={CodeEntryScreenshot}
                    className='content-section-image screenshot-image'
                  /> */}
                </div>

                <div className='content-section-wrapper'>
                  <h4 className='content-section-heading'>Payment &amp; Tax</h4>
                  <p className='content-section-paragraph'>
                    You will receive the commission in your bank account within
                    3 working days from the day sale has happened.
                  </p>

                  <p className='content-section-paragraph'>
                    You may allow us to deduct tax at source (TDS) on your
                    commission, in that case you will need to provide extra
                    information and upload identification documents. You may
                    choose to decline TDS. Please choose the appropriate option
                    in the form.
                  </p>
                </div>
              </div>

              <div
                ref={this.agentFormRef}
                className='form-section form-section--separator'
              >
                <h3 className='form-section-title'>
                  Ambassador Registration Form
                </h3>
              </div>

              <div className='form-section'>
                <h4 className='form-section-heading'>Personal Details</h4>

                {validationErrors.length !== 0 && (
                  <div className='validation-errors'>
                    {validationErrors.map((item, index) => {
                      return (
                        <React.Fragment key={index}>{item}</React.Fragment>
                      );
                    })}
                  </div>
                )}

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='firstName' className='input-label'>
                        First Name
                      </label>
                      <input
                        type='text'
                        id='firstName'
                        placeholder='Aayush'
                        className='input-field'
                        value={formData.firstName}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'firstName',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='lastName' className='input-label'>
                        Last Name
                      </label>
                      <input
                        type='text'
                        id='lastName'
                        placeholder='Dutta'
                        className='input-field'
                        value={formData.lastName}
                        onChange={(event) =>
                          this.handleFieldUpdate('lastName', event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='email' className='input-label'>
                        Email Address
                      </label>
                      <input
                        type='email'
                        id='email'
                        placeholder='abc@example.com'
                        className='input-field'
                        required={true}
                        value={formData.email}
                        onChange={(event) =>
                          this.handleFieldUpdate('email', event.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='mobileNumber' className='input-label'>
                        Mobile Number
                      </label>
                      <input
                        type='number'
                        id='mobileNumber'
                        className='input-field'
                        placeholder='9876543210'
                        required={true}
                        value={formData.mobileNumber}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'mobileNumber',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-section'>
                <h4 className='form-section-heading'>Payout Details</h4>

                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='accountNumber' className='input-label'>
                        Account Number
                      </label>
                      <input
                        type='number'
                        id='accountNumber'
                        placeholder='12345678901234'
                        className='input-field'
                        required={true}
                        value={formData.accountNumber}
                        onChange={(event) =>
                          this.handleFieldUpdate(
                            'accountNumber',
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className='col-12 col-sm-6'>
                    <div className='input-wrapper'>
                      <label htmlFor='ifscCode' className='input-label'>
                        IFSC Code
                      </label>
                      <input
                        type='text'
                        id='ifscCode'
                        placeholder='QWERT0123456'
                        className='input-field'
                        required={true}
                        value={formData.ifscCode}
                        onChange={(event) =>
                          this.handleFieldUpdate('ifscCode', event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className='input-wrapper'>
                      <label htmlFor='consentForTDS' className='input-label'>
                        <input
                          required={true}
                          type='checkbox'
                          id='consentForTDS'
                          className='input-checkbox'
                          value={formData.consentForTDSProvided}
                          onChange={() => this.handleTDSConsent()}
                        />
                        I allow Wise Owl Learning Private Limited to deduct TDS.
                        I am willing to upload my PAN card.
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {formData.consentForTDSProvided && (
                <div className='form-section'>
                  <h4 className='form-section-heading'>TDS Details</h4>

                  <div className='row align-items-center'>
                    <div className='col-12 col-sm-6'>
                      <div className='input-wrapper'>
                        <label htmlFor='panCardNumber' className='input-label'>
                          PAN Number
                        </label>
                        <input
                          type='text'
                          id='panCardNumber'
                          placeholder='ABCDE1234F'
                          className='input-field'
                          required={true}
                          value={formData.panCardNumber}
                          onChange={(event) =>
                            this.handleFieldUpdate(
                              'panCardNumber',
                              event.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className='col-12 col-sm-6'>
                      <div className='input-wrapper'>
                        <label htmlFor='panCard' className='input-label'>
                          Upload PAN Card
                        </label>
                        <input
                          type='file'
                          accept='jpg,jpeg,png,pdf'
                          id='panCard'
                          className='input-field'
                          required={true}
                          onChange={(event) => this.handleFileUpload(event)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row align-items-center'>
                    <div className='col-12'>
                      <p className='input-assist'>
                        Upload a scanned copy of the same <b>PAN Card</b> whose
                        number you've entered above. We accept
                        <b> JPG</b>, <b>PNG</b> or <b>PDF</b> file formats.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className='form-section'>
                <h4 className='form-section-heading'>Consent & Disclaimer</h4>

                <div className='row'>
                  <div className='col-12'>
                    <div className='input-wrapper'>
                      <label htmlFor='agreeTerms' className='input-label'>
                        <input
                          type='checkbox'
                          id='agreeTerms'
                          required={true}
                          className='input-checkbox'
                          onChange={() => this.handleCheckboxUpdate()}
                        />
                        All information provided in this form is accurate to
                        best of my knowledge. I agree to Wise Owl Learning
                        Private Limited's
                        <a
                          target='_new'
                          rel='noopener noreferrer'
                          href='https://cdn.wiseowllearning.io/media/c41e5a75-8c35-4e0e-a129-1710fc2bb587/2859f2c5f6e0d044099d5120c3c4e2c4.pdf'
                        >
                          Terms & Conditions
                        </a>
                        . Further I agree to the following:
                        <ul>
                          {formData.consentForTDSProvided && (
                            <li>
                              I provide them my explicit consent to verify my
                              PAN Card information with the government
                              authorities (if provided by me).
                            </li>
                          )}
                          <li>
                            I take complete responsibility of the financial
                            information provided by me, that I have checked and
                            re-checked this for correctness and will not hold
                            Wise Owl Learning Private Limited responsible for
                            missed payouts, mis-transfer of payouts or
                            non-receipt due to incorrect information provided
                            herein.
                          </li>
                        </ul>
                        I hereby also agree that my registration is provisional
                        and is subject to successful verification of the
                        information provided in this form.
                      </label>
                    </div>
                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-12 col-sm-6'>
                    <button
                      type='button'
                      className='button button-primary'
                      id='submitForm'
                      disabled={!isSubmitEnabled}
                      onClick={() => this.handleFormSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='container-wrapper-form'>
              <div className='form-section'>
                <h1 className='form-section-heading'>Success!</h1>

                <p className='form-section-description'>
                  Thank you for your interest in becoming an Ambassador for
                  Skipin. Your unique Ambassador code will be sent within 2
                  working days of successful registration.
                </p>

                <br />

                <p className='form-section-description'>
                  Please Note: Your registration is provisional, and it is
                  subject to validation of the information provided by you from
                  the relevant authorities.
                </p>
              </div>
            </div>
          )}
        </main>

        <footer className='footer-section text-center'>
          <p className='footer-section-text'>
            &copy; Copyright 2021 - Wise Owl Learning Private Limited
          </p>

          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.wiseowllearning.io'
          >
            <img
              alt='Skipin'
              src={WiseOwlLogo}
              className='footer-section-logo'
            />
          </a>
        </footer>

        {pageLoader && <PageLoader />}
      </>
    );
  }
}
