import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './containers/Home';

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

const App = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home} />
      </Switch>
    </Router>
  );
};

export default App;
